import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=173e6b08&scoped=true&"
import script from "./admin.vue?vue&type=script&lang=js&"
export * from "./admin.vue?vue&type=script&lang=js&"
import style0 from "./admin.vue?vue&type=style&index=0&id=173e6b08&prod&lang=scss&scoped=true&"
import style1 from "./admin.vue?vue&type=style&index=1&id=173e6b08&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173e6b08",
  null
  
)

export default component.exports